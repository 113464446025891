.form {
  @media only screen and (max-width: 767px) {
    display: none;
  }
}

.file {
  margin-top: 1rem;
}

.table {
  width: 100%;
  margin: 1rem 0;
  th,
  td {
    border: 1px solid #ddd;
  }
  td {
    &:first-child {
      width: 120px;
      text-align: center;
    }
    &:last-child {
      text-align: right;
    }
  }
}

.label {
  display: block;
  margin-bottom: 1rem;
  span {
    display: block;
  }
  select {
    border: 1px solid #ccc;
    background-color: transparent;
    margin: 0.5rem 0 0;
    height: 2rem;
    width: 230px;
    color: #fff;
  }
}

.submit {
  height: 2rem;
  padding: 0 0.5rem;
  border: none;
  cursor: pointer;
  background-color: #f5f5f5;
  color: #333;

  &[disabled] {
    opacity: 0.8;
    cursor: not-allowed;
  }
}
