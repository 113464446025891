.up {
  color: #50a684;
}
.down {
  color: #e1523e;
}
.netural {
  color: #ffa400;
}

.tableWrapper {
  width: 100%;
  overflow: auto;
  margin-bottom: 2rem;
}

.returnsTable {
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
  min-width: 660px;
  th,
  td {
    border: 1px solid #fff;
    text-align: center;
    font-size: 0.8rem;
  }
  td {
    &.negative {
      background-color: #e1523e;
    }
    &.positive {
      background-color: #50a684;
      color: #000;
    }
    &.neutral {
      background-color: #ffa400;
      color: #000;
    }
  }
}

.buttons {
  margin-bottom: 1rem;
  .label {
    display: inline-block;
    padding-right: 0.5rem;
  }
  .options {
    display: inline-block;
    button {
      cursor: pointer;
      height: 2rem;
      padding: 0 0.5rem;
      background-color: #fff;
      color: #2271b1;
      border: 1px solid #2271b1;
      font-size: 0.9em;
      &.selected {
        background-color: #2271b1;
        color: #fff;
        font-weight: bold;
      }
    }
  }
}

.assetsTable {
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
  line-height: 1.7;
  font-size: 0.9rem;
  overflow: hidden;
  th,
  td {
    padding-right: 1em;
    white-space: nowrap;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    border-bottom: 1px solid #333;
    &:hover {
      text-overflow: clip;
      overflow: visible;
      position: relative;
      z-index: 1;
      span,
      strong {
        padding-right: 5px;
      }
    }
    &.name {
      min-width: 250px;
    }
    &.qty {
      width: 140px;
      @media only screen and (max-width: 767px) {
        display: none;
      }
    }
    &.quote {
      width: 110px;
      @media only screen and (max-width: 767px) {
        display: none;
      }
    }
    &.target {
      width: 90px;
      @media only screen and (max-width: 767px) {
        display: none;
      }
    }
    &.percentage {
      width: 70px;
    }
    &.total {
      width: 100px;
    }
    &.pl {
      width: 150px;
    }
    &:first-child {
      padding-left: 0.5em;
    }
    &:last-child {
      padding-right: 0.5em;
    }
  }
  tr,
  span,
  strong {
    &.stock {
      background-color: #e1523e;
    }
    &.bond {
      background-color: #2271b1;
    }
    &.gold {
      background-color: #ffa400;
      color: #000;
    }
    &.ppr {
      background-color: #80f3d9;
      color: #000;
    }
    &.crypto {
      background-color: #712f79;
    }
    &.cash {
      background-color: #50a684;
      color: #000;
    }
    &.total {
      background-color: #000;
      color: #fff;
    }
  }
  b {
    display: inline-block;
    width: 25px;
    text-align: center;
  }
}

.rebalance {
  margin-bottom: 1rem;
  button {
    height: 2rem;
    padding: 0 0.5rem;
    border: none;
    cursor: pointer;
    background-color: #f5f5f5;
    color: #333;
  }
  fieldset {
    margin-top: 1rem;
  }
  label {
    display: block;
    margin-bottom: 0.5rem;
    span {
      display: inline-block;
      min-width: 150px;
    }
  }
}

.pieChartContainer {
  max-width: 500px;
  margin: 0 auto;
}
