.tabs {
  ul {
    margin: 1rem 0 0.5rem;
    padding: 0;
    border-bottom: 1px solid #333;
    width: 100%;
    display: flex;
  }
  li {
    list-style: none;
    display: flex;
    flex-shrink: 0;
    border-top: 1px solid #333;
    border-left: 1px solid #333;
    border-right: 1px solid #333;
    & + li {
      border-left: 0;
    }
  }
  button {
    border: 0;
    background-color: transparent;
    padding: 0.25rem 0.5rem;
    color: #fff;
    cursor: pointer;
    font-size: 0.8rem;
    &.selected {
      background-color: #fff;
      color: #333;
      font-weight: bold;
    }
  }
}

.buttons {
  margin-bottom: 1rem;
  .label {
    display: block;
    margin-bottom: 0.5rem;
  }
  .options {
    display: inline-block;
    button {
      cursor: pointer;
      padding: 0.25rem 0.5rem;
      background-color: #fff;
      color: #2271b1;
      border: 1px solid #2271b1;
      font-size: 0.8rem;
      & + button {
        border-left: 0;
      }
      &.selected {
        background-color: #2271b1;
        color: #fff;
      }
    }
  }
}

.change {
  margin-bottom: 1rem;
  font-size: 0.8rem;
  .positive {
    color: #50a684;
  }
  .negative {
    color: #e1523e;
  }
  .neutral {
    color: #ffa400;
  }
}
