.accounts {
  list-style: none;
  padding: 0;
  font-size: 80%;
}

.button {
  display: block;
  margin-top: 1rem;
  height: 2rem;
  padding: 0 0.5rem;
  border: none;
  cursor: pointer;
  background-color: #f5f5f5;
  color: #333;
}

.up {
  color: #50a684;
}
.down {
  color: #e1523e;
}
.netural {
  color: #ffa400;
}

.pieContainer {
  height: 600px;
  position: relative;
  @media only screen and (max-width: 767px) {
    display: none;
  }
}

.pieContainerMobile {
  display: none;
  height: 100vw;
  position: relative;
  @media only screen and (max-width: 767px) {
    display: block;
  }
}

.transactionDates {
  list-style: none;
  padding: 0;
}

.transactionDate {
  font-weight: bold;
}

.transactions {
  margin: 1rem 0;
  list-style: none;
  padding: 0;
}

.transaction {
  display: flex;
  background-color: #444;
  margin: 1rem 0;
  justify-content: space-between;
  font-size: 0.8rem;
  padding: 10px;
  .checkbox{
    padding-right: 5px;
  }
  .category {
    flex-grow: 0;
    flex-shrink: 0;
    width: 25px;
    text-align: center;
    padding-right: 5px;
    cursor: pointer;
  }
  .account {
    flex-grow: 0;
    flex-shrink: 0;
    width: 140px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @media only screen and (max-width: 1023px) {
      display: none;
    }
  }
  .description {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    padding: 0 5px;
  }
  .value {
    width: 100px;
    flex-grow: 0;
    flex-shrink: 0;
    text-align: right;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: bold;
    &.positive {
      color: #50a684;
    }
    &.negative {
      color: #e1523e;
    }
  }
}
