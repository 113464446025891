.form {
  text-align: center;
}

.label {
  display: block;
  margin: 0 auto 1rem;
  max-width: 700px;
}

.input {
  border: 1px solid #ccc;
  background-color: transparent;
  margin: 1rem 1rem 0;
  height: 2rem;
  padding: 0 0.5rem;
  width: 230px;
  color: #fff;
}

.submit {
  margin-top: 1rem;
  height: 2rem;
  padding: 0 0.5rem;
  border: none;
  cursor: pointer;
  background-color: #f5f5f5;
  color: #333;
  &[disabled] {
    opacity: 0.8;
  }
}
