.recentPosts {
  margin-top: 4rem;
}

.title {
  font-size: 1.2rem;
}

.list {
  padding: 0;
  list-style: none;
}

.article {
  padding: 0.2em 0;
}