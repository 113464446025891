.header {
  position: fixed;
  top: 0;
  width: 100%;
  box-sizing: border-box;
  text-transform: lowercase;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #222;
  border-bottom: 1px solid #333;
  white-space: nowrap;
  overflow: auto;
  padding: 0 2rem;
  z-index: 1000;
  @media only screen and (max-width: 1023px) {
    padding: 0 1rem;
  }
}

.login {
  border: none;
  background-color: transparent;
  cursor: pointer;
  @media only screen and (max-width: 1023px) {
    display: none;
  }
}

nav {
  display: flex;
}

.link {
  padding: 10px;
  display: inline-block;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
  @media only screen and (max-width: 1023px) {
    padding: 10px 0;
  }
}

.extraLinks {
  .login {
    display: none;
    @media only screen and (max-width: 1023px) {
      display: block;
      margin-top: 10px;
    }
  }
  @media only screen and (max-width: 1023px) {
    display: none;
    background-color: #222;
    &.open {
      display: flex;
      flex-direction: column;
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      text-align: center;
      justify-content: center;
    }
  }
}

$buttonSize: 2rem;
.buttonIcon {
  position: relative;
  cursor: pointer;
  width: $buttonSize;
  height: $buttonSize;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  transition: all 0.3s ease;
  background-color: transparent;
  border: none;
  display: none;
  @media only screen and (max-width: 1023px) {
    display: block;
  }
  &:hover {
    opacity: 0.8;
  }
  .top,
  .mid,
  .bot {
    background-color: #ddd;
    height: ($buttonSize * 0.9) / 5;
    width: $buttonSize * 0.9;
    position: absolute;
    left: $buttonSize * 0.05;
    transition: all 0.3s ease;
  }
  .top {
    top: $buttonSize * 0.05;
  }
  .mid {
    top: ((($buttonSize * 0.9) / 5) * 2) + $buttonSize * 0.05;
  }
  .bot {
    top: ((($buttonSize * 0.9) / 5) * 4) + $buttonSize * 0.05;
  }
  &.active {
    transform: rotate(90deg);
    .top {
      top: ((($buttonSize * 0.9) / 5) * 2) + $buttonSize * 0.05;
      transform: rotate(45deg);
    }
    .mid {
      opacity: 0;
      transform: rotate(45deg);
    }
    .bot {
      top: ((($buttonSize * 0.9) / 5) * 2) + $buttonSize * 0.05;
      transform: rotate(-45deg);
    }
  }
}
