.title {
  font-size: 1.5rem;
}

.list {
  padding: 0;
  ul {
    padding: 0;
    margin-bottom: 1em;
  }
  li {
    list-style: none;
  }
}

.year {
  margin-top: 3rem;
  .yearLabel {
    font-size: 1.25rem;
    margin-bottom: 1em;
  }
}

.month {
  margin-top: 2rem;
  .monthLabel {
    margin-bottom: 1em;
  }
}

.article {
  border-bottom: 1px solid #333;
  padding: 1rem 0;
  display: flex;
  .day {
    display: inline-block;
    width: 4rem;
    flex-shrink: 0;
  }
}
