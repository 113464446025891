.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 9000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.label {
  display: block;
  margin-bottom: 1rem;
  span {
    display: block;
  }
  select {
    border: 1px solid #ccc;
    background-color: transparent;
    margin: 0.5rem 0 0;
    height: 2rem;
    width: 230px;
    color: #fff;
  }
}

.error {
  color: #e1523e
}

.submit {
  height: 2rem;
  padding: 0 0.5rem;
  margin-right: 1rem;
  border: none;
  cursor: pointer;
  background-color: #f5f5f5;
  color: #333;
  &[disabled] {
    opacity: 0.8;
    cursor: not-allowed;
  }
}
