html {
  font-family: 'Lato', sans-serif;
  font-size: 18px;
  letter-spacing: 0.05em;
  color: #fff;
  background-color: #222;
}

body {
  margin: 0;
  padding-top: 4rem;
}

main {
  display: flex;
  box-sizing: border-box;
  min-height: calc(100vh - 4rem - 300px);
  margin: 0 auto;
  max-width: 900px;
  padding: 2rem 1rem;
  overflow: hidden;
  > * {
    width: 100%;
  }
}

hr {
  margin: 3em 0;
}

a {
  color: #ddd;
  &:hover {
    opacity: 0.8;
    text-decoration: none;
  }
}

.article-body,
.markdown {
  line-height: 1.5;
  a {
    color: #52b1dd;
    &:hover {
      color: #5f7782;
    }
  }
  h2 {
    font-size: 1.2rem;
  }
  h3 {
    font-size: 1.1rem;
  }
  img {
    max-width: 100%;
  }
  code,
  blockquote {
    padding: 0.5em 1em;
    margin: 2.5em 0;
    background-color: #333;
    border-left: 0.5em solid #666;
  }
  code {
    display: block;
    font-family: 'Azeret Mono', monospace;
    overflow: auto;
    font-size: 0.8em;
  }
  blockquote {
    p {
      display: inline;
    }
  }
}

.article-body {
  > section {
    h1 {
      font-size: 1.2rem;
    }
    h2 {
      font-size: 1.1rem;
    }
  }
}
