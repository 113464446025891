.high,
.warning {
  color: #ffa400;
}

.fieldset {
  margin: 1em 0;
}

.label {
  display: block;
  margin: 0.5em 0;
}

.low,
.success {
  color: #50a684;
}

.very_high,
.fail {
  color: #e1523e;
}

.medium {
  color: #ffe459;
}

.range {
  vertical-align: middle;
}

.shareLink {
  width: 100%;
  display: flex;
  justify-content: space-between;
  input {
    width: 100%;
  }
  button {
    margin-left: 1em;
    white-space: nowrap;
  }
}

.chart {
  margin: 2em 0;
}

.tableContainer {
  width: 100%;
  overflow: auto;
  .table {
    width: 100%;
    white-space: nowrap;
    th,
    td {
      text-align: left;
      padding: 0 0.5em;
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }
    }
    .boost {
      &.acc {
        color: #50a684;
      }
      &.dist {
        color: #e1523e;
      }
    }
    .dead {
      color: #e1523e;
    }
  }
}
